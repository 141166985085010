
import axios from 'axios';
import { getComponent, getConfigEnv } from '@/utils/helpers';

import { Component, Vue } from 'vue-property-decorator';
import parsePhoneNumber from 'libphonenumber-js';
import VuePhoneNumberInput from 'vue-phone-number-input';
import workflowModule from '@/store/modules/workflowModule';
import accountModule from '@/store/modules/accountModule';
import userProfileModule from '@/store/modules/userProfileModule';
import Multiselect from 'vue-multiselect';

@Component({
  components: {
    AuthLayout: () => getComponent('auth/AuthLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    Multiselect,
    VuePhoneNumberInput,
  },
})
export default class VerifyInfo extends Vue {
  CODE_LENGTH = 6;

  email = '';

  phoneNumber = '';

  userNationalNumber: any = '';

  userFullFormatNumber: any = {};

  userCountryCode: any = 'US';

  firstName = '';

  lastName = '';

  userTeam: any = [];

  actionShown = 'signup';

  code = ['', '', '', '', '', ''];

  errorMessage = '';

  emailErrorMessage = '';
  firstNameErrorMessage = '';
  lastNameErrorMessage = '';
  roleErrorMessage = '';
  phoneNumberErrorMessage = '';

  loading = false;

  dataLoading = false;

  savingUserDetails = false;

  userRole = '';

  get teams() {
    const structuredTeams: any = [];
    // eslint-disable-next-line no-plusplus
    for (let x = 0; x < workflowModule.teams.length; x++) {
      structuredTeams.push(workflowModule.teams[x]);
    }
    return structuredTeams;
  }

  get isValidPhone() {
    if (this.userFullFormatNumber && this.userFullFormatNumber.formattedNumber) {
      return parsePhoneNumber(this.userFullFormatNumber.formattedNumber)?.isValid();
    }
    return false;
  }

  async created() {
    this.dataLoading = true;
    await workflowModule.listTeams({ useExisting: true });
    await workflowModule.getUserProfile({
      username: accountModule.user.email.toLowerCase(),
    });

    if (workflowModule.user && workflowModule.user.isVerified) {
      this.$router.push({ name: 'Home' });
    }

    this.setUserDetailForm();

    this.dataLoading = false;
  }

  async saveProfile() {
    try {
      this.savingUserDetails = true;
      if (this.userFullFormatNumber) {
        this.phoneNumber = this.userFullFormatNumber.formattedNumber;
      }
      await userProfileModule.updateUserProfile({
        username: accountModule.user.username.toLowerCase(),
        accepting_tasqs: true,
        first_name: this.firstName,
        last_name: this.lastName,
        team: this.userTeam ? [this.userTeam] : [],
        phone_number: this.phoneNumber,
        roles: [this.userRole],
        default_page: '',
        default_user_filter: '',
        custom_profile_json: '',
        user_email: accountModule.user.email.toLowerCase(),
      });

      await workflowModule.getUserProfile({ username: accountModule.user.email.toLowerCase(), useExisting: false });

      this.$router.push({ name: 'Home' });
    } catch (error) {
      console.log(error);
      this.$tasqAlert({
        title: 'Error',
        message: 'Profile update failed!, Please try again. ',
        type: 'error',
      });
    }

    this.savingUserDetails = false;
  }

  setUserDetailForm() {
    this.firstName = workflowModule.user.firstName;
    this.lastName = workflowModule.user.lastName;
    this.email = workflowModule.user.email;
    this.userNationalNumber = '';
    this.phoneNumber = workflowModule.user.phoneNumber;

    // this.username = workflowModule.user.username.toLowerCase();
    // this.userInitial = workflowModule.user.firstName.charAt(0) + workflowModule.user.lastName.charAt(0);
    this.userRole = workflowModule.user.role;
    if (this.phoneNumber) {
      this.userCountryCode = parsePhoneNumber(this.phoneNumber)?.country;
      this.userNationalNumber = parsePhoneNumber(this.phoneNumber)?.nationalNumber;
    }

    this.userTeam = workflowModule.user.team ? workflowModule.user.team : null;
  }
}
